import Vue from 'vue'
const Vant = require('vant')
import 'vant/lib/index.css'
import { Toast } from 'vant'

import App from './App.vue'
import router from './router'

import WEBAPI from 'apifm-webapi'
WEBAPI.init('gifer')

Vue.prototype.$wxapi = WEBAPI

Vue.use(Vant)
Vue.use(Toast)

new Vue({
    render: h => h(App),
    router
}).$mount('#app')




