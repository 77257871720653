import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/apps',
    },
    {
        path: '/apps',
        name: 'apps',
        component: () => import('../view/home/index.vue'),
    },
    {
        path: '/detail',
        name: 'detail',
        component: () => import('../view/detail/index.vue'),
    }
]

const router = new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes
})

export default router
